import React from "react"
import styled from "styled-components"

const SCHeaderTwo = styled.h2`
    color: var(--theme-blue);
    font-size: 4rem;
    font-family: var(--font-raleway-bold);

    @media (min-width: 800px) {
        font-size: 5rem;
    }
`

const SCHeaderThree = styled.h3`
    color: var(--theme-blue);
    font-size: 3rem;
    font-family: var(--font-raleway-bold);

    @media (min-width: 800px) {
        font-size: 4rem;
    }
`

const SCHeaderFour = styled.h4`
    color: var(--theme-blue);
    font-size: 2rem;
    font-family: var(--font-raleway-bold);

    @media (min-width: 800px) {
        font-size: 2.4rem;
    }
`

export const HeaderTwo = ({ text, id }) => {
    return <SCHeaderTwo id={id}>{text}</SCHeaderTwo>
}

export const HeaderThree = ({ text, id }) => {
    return <SCHeaderThree id={id}>{text}</SCHeaderThree>
}

export const HeaderFour = ({ text, id }) => {
    return <SCHeaderFour id={id}>{text}</SCHeaderFour>
}
