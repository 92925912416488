import React from "react"
import styled from "styled-components"

const SCPlane = styled.svg`
    fill: var(--theme-coral);
    left: 2px;
    position: relative;
    transform: translateZ(0);
    transition: all 0.3s;
    width: 20px;
`

const SCFLames = styled.svg`
    fill: var(--theme-coral);
    left: 3px;
    position: absolute;
    top: 17px;
    transform: translateX(0) scale(0);
    transition: all 0.8s;
    width: 16px;
`

const SCButton = styled.button`
    align-self: flex-end;
    background-color: #fff;
    border-radius: 100%;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    outline: none;
    padding: 12px;
    position: relative;
    transition: all 0.3s;

    &.is-disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    &:focus,
    &:hover {
        ${SCPlane} {
            left: 7px;
        }

        ${SCFLames} {
            transform: translateX(0) scale(1);
        }
    }
`

const SendButton = ({ submitting }) => {
    return (
        <SCButton
            className={submitting ? "is-disabled" : ""}
            type="submit"
            aria-label="submit form"
        >
            <SCPlane
                version="1.1"
                viewBox="0 0 21 21"
                xmlns="http://www.w3.org/2000/svg"
            >
                <polygon
                    id="Shape"
                    points="1.9373494 20.2409639 22.1686747 11.5662651 1.9373494 2.89156627 1.92771084 9.63855422 16.3855422 11.5662651 1.92771084 13.4939759"
                    transform="translate(-1.500000)"
                ></polygon>
            </SCPlane>
            <SCFLames version="1.1" viewBox="0 0 17 11">
                <path
                    d="M2.64783571,15.9789684  C2.80410729,16.0620533 2.96895315,15.8821318 2.87327668,15.7332168 C2.35395731,14.9248771 1.86219068,13.5646681 2.64569214,11.8129037 C3.95227729,8.89167545 4.74733312,7.37868224 4.74733312,7.37868224 C4.74733312,7.37868224 5.17021792,9.14432901 6.31655804,10.7142372 C7.41984374,12.2251651 8.02372961,14.1254262 7.05018225,15.7084586 C6.95881906,15.8570338 7.11992675,16.0323279 7.27517882,15.9528769 C8.48028416,15.3363541 9.83193659,14.0996746 9.98444385,11.6387077 C10.0406734,10.8867078 9.95634215,9.83389752 9.53465985,8.50534872 C8.99246701,6.82153201 8.32592089,6.03583279 7.94026104,5.69842109 C7.8249003,5.59747993 7.64549384,5.68694396 7.65495692,5.8399374 C7.76733757,7.6567737 7.08390429,8.11781937 6.69489838,7.07878687 C6.53954174,6.6637804 6.44893664,5.9459766 6.44893664,5.07183295 C6.44893664,3.61653908 6.02675765,2.11844783 5.0960033,0.901088553 C4.85396272,0.584513442 4.5707499,0.288696498 4.24581585,0.0364351021 C4.12818084,-0.0549112879 3.95766236,0.0369056903 3.9684325,0.185454685 C4.0399023,1.17201661 3.97515076,3.99944098 1.49485575,7.37732277 C-0.754038128,10.5106817 0.11740204,12.9170865 0.426625275,13.5688511 C1.0178327,14.8174522 1.84248028,15.550707 2.64783571,15.9789684 Z"
                    id="Shape"
                    transform="translate(0, 11.00000) rotate(-90)"
                ></path>
            </SCFLames>
        </SCButton>
    )
}

export default SendButton
