import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ButtonWrapper = styled.button.attrs(props => ({
    ...props.attributes,
}))`
    border: none;
    color: var(--theme-bb);
    cursor: pointer;
    font-size: 1.6rem;
    margin: 4px;
    padding: 0;
    position: relative;
    transition: transform 0.3s;

    &:active,
    &:focus,
    &:hover {
        outline: none;
        transform: scale(1.05);
    }

    .border {
        background: linear-gradient(45deg, var(--theme-bb), var(--theme-coral));
        border-radius: 4px;
        height: calc(100% + 4px);
        left: -2px;
        position: absolute;
        top: -2px;
        width: calc(100% + 4px);
    }

    .text {
        transition: background 0.3s ease;
        background: white;
        border-radius: 2px;
        padding: 11px 16px;
        position: relative;
    }
`

const Button = ({ to, text, event, attributes, toExt }) => {
    if (to) {
        return (
            <Link to={to}>
                <ButtonWrapper onClick={event} attributes={attributes}>
                    <div className="border"></div>
                    <div className="text">{text}</div>
                </ButtonWrapper>
            </Link>
        )
    } else if (toExt) {
        return (
            <a href={toExt} rel="noopener noreferrer" target="_blank">
                <ButtonWrapper onClick={event} attributes={attributes}>
                    <div className="border"></div>
                    <div className="text">{text}</div>
                </ButtonWrapper>
            </a>
        )
    } else {
        return (
            <ButtonWrapper onClick={event} attributes={attributes}>
                <div className="border"></div>
                <div className="text">{text}</div>
            </ButtonWrapper>
        )
    }
}

export default Button
